var wW = $(window).width();
function repositionLatestEventCalendar() {
	var windowWidth = $(window).width();
	if(windowWidth > 767) {
		var listContainer = $('.latest-events__list');
		var offset = windowWidth - (listContainer.outerWidth(true) + listContainer.offset().left);
		$('.latest-events__calendar-container').css({
			width: offset
		});
	}
}

function resizeGallery() {
	if($('.main-slider').length) {
		var h = $('.main-slider img').height();
		$('.main-slider__preview-slider').css('height', h);
		$('.main-slider__preview-item').each(function(){
			$(this).css({
				'height' : h/3,
				'line-height': h/3+"px"
			});
		})
	}
}

function showFancyMessage(msg) {
	$.fancybox.close();
	$.fancybox.open('<div class="fancy-message fancy--custom">'+msg+'</div>');
}

function getCareerAutocomplete() {
	$.ajax({
		url: '/other/autocomplete.json',
		method: 'get',
		dataType: 'json'
	}).done(function(data) {
		var output = "";
		$.each(data, function(index, val){
			output += '<div class="careers__autocomplete-item">'+val.text+'</div>';
		});
		$('.careers__autocomplete').html(output).show();
	});
}

$(document).ready(function(){
	
	$('.simple-datepicker').datepicker({
		language: 'ru-RU'
	});

	$(document).on('click', '.veterans__item, .fancy-show', function (e) {
		e.preventDefault();
		var href = $(this).attr('href');
		$.fancybox.close();
		$.fancybox.open({
			src: href
		});
	})
	$('.olympics-item__show-full').click(function (e) {
		e.preventDefault();
		$(this).prev('.olympics-item__full-text').toggle(200);
	})
	$('.important-information__list').slick({
		prevArrow: '<button type="button" class="important-information__arrow important-information__arrow--prev"><svg><use xlink:href="/img/sprite.svg#icon-arrow-right"></use></svg></button>',
		nextArrow: '<button type="button" class="important-information__arrow important-information__arrow--next"><svg><use xlink:href="/img/sprite.svg#icon-arrow-left"></use></svg></button>',
		infinite: false,
		slideToShow: 1,
		fade: true
	});
	$('.accordion').rkAccordion();
	if ($('.main-slider__slide-container').length) {
		$(this).find('.main-slider__slide-item').show();
		$('.main-slider__slide-container').slick({
			appendDots: $('.main-slider__preview-slider'),
			prevArrow: '<button type="button" class="main-slider__arrow main-slider__arrow--prev"><svg><use xlink:href="/img/sprite.svg#icon-arrow-right"></use></svg></button>',
			nextArrow: '<button type="button" class="main-slider__arrow main-slider__arrow--next"><svg><use xlink:href="/img/sprite.svg#icon-arrow-left"></use></svg></button>',
			autoplay: true,
			autoplaySpeed: 6000,
			// customPaging: function(slider, i) {
			// 	return $('<button type="button" class="main-slider__preview-item"><span>'+$(slider.$slides[i].outerHTML).find('img').attr('alt')+'</span></button>');
			// },
			// appendArrows: $('.main-slider__slide-container')
		});
	}
	setInterval(function(){
		$('input[name="intr"]').each(function(i){
			$(this).val(parseInt($(this).val())+3);
		});
	}, 3000);

	$('.show-question-form').click(function(e){
		e.preventDefault();
		$.fancybox.close();
		$.fancybox.open({
			src: '#question_form'
		});
	});
	$('#question_form form').submit(function(e){
		e.preventDefault();
		showFancyMessage('Ваш вопрос отправлен.');
	});

	if($('.latest-events__calendar-container').length) {
		repositionLatestEventCalendar();
		$('#event_calendar').eCalendar({
			url: '/other/events.json',
			weekDays: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    	months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    	textArrows: {
    		previous: '<svg class="icon icon-arrow_calendar-left"><use xlink:href="/img/sprite.svg#icon-arrow_calendar-left"></use></svg>', 
    		next: '<svg class="icon icon-arrow_calendar-right"><use xlink:href="/img/sprite.svg#icon-arrow_calendar-right"></use></svg>'
    	}
		});
	}
	$(window).resize(function(){
		if($('.latest-events__calendar-container').length) {
			repositionLatestEventCalendar();
		}	
		resizeGallery();
	});

	$('.partners-tabs, .tabs').rkTabs({
		activeCaptionClass: 'blue-menu__item--active'
	});

	$('.years-tabs').rkTabs({
		activeCaptionClass: 'years-tabs__caption--active'
	});

	$('.careers__serach-filed').keyup(function(e) {
		var text = $(this).val();
		if(text.length >= 3) {
			getCareerAutocomplete();
		}
	})
	$('.careers__serach').on('click', '.careers__autocomplete-item', function(e){
		e.preventDefault();
		$('.careers__serach-filed').val($(this).html()).focus();
		$('.careers__autocomplete').hide();
	});
	$(document).on('click', function(e){
		var container = $(".careers__autocomplete");
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      container.hide();
    }
	});
	resizeGallery();
	

	$('.sidebar-menu-caption').click(function (e) {
		$(this).toggleClass('sidebar-menu-caption--open');
		$(this).nextAll('.sidebar-menu').toggle();
	})
	$('.mobile-blue-menu__current').click(function (e) {
		e.preventDefault();
		$(this).toggleClass('mobile-blue-menu__current--active')
			.next('.mobile-blue-menu__dropdown').toggle();
	});
	$('.show-full-menu, .full-menu-contauner__close').click(function (e) {
		e.preventDefault();
		$('.full-menu-contauner').toggle();
		$('html').toggleClass('full-menu-open');
	});

	$('.accordion-tabs').children('li').first().children('a').addClass('is-active').next().addClass('is-open').show();
	$('.accordion-tabs').on('click', 'li > a', function (event) {
		if (!$(this).hasClass('is-active')) {
			event.preventDefault();
			$('.accordion-tabs .is-open').removeClass('is-open').hide();
			$(this).next().toggleClass('is-open').toggle();
			$('.accordion-tabs').find('.is-active').removeClass('is-active');
			$(this).addClass('is-active');
		} else {
			event.preventDefault();
		}
	});

	var menu = $('.years-menu');
	if(menu.length){
		var menuItems = $('.years-menu__item');
		var mainPosition = menu.offset().top;
		var positions = [];
		menuItems.each(function(){
			var top = parseInt($($(this).attr('href')).offset().top);
			var pos = {
				item: $(this).attr('href'), 
				top: top,
				bottom: 0
			}
			if(positions.length > 0)
				positions[positions.length - 1].bottom = top;

			if(positions.length + 1 == menuItems.length) {
				pos = {
					item: $(this).attr('href'), 
					top: top,
					bottom: parseInt($('.footer').offset().top)
				}
			}

			positions.push(pos);
		});

		function yearMenuPosition() {
			if(($(window).scrollTop() + 20) > mainPosition) {
				menu.addClass('years-menu--fixed');
			} else {
				menu.removeClass('years-menu--fixed');
			}
		}
		$(window).scroll(function(e){
			yearMenuPosition();
			var center = $(window).scrollTop() + $(window).height() / 2;
			$.each(positions, function(index, value) {
				if((value.top < center) && (center < value.bottom)) {
					menuItems.filter('[href="'+value.item+'"]').addClass('years-menu__item--active').siblings().removeClass('years-menu__item--active');
					
					
				}
			})
		});
		menuItems.click(function(e){
			e.preventDefault();
			$('html, body').animate({
				scrollTop: $($(this).attr('href')).offset().top - 30
			},200);
			$(this).addClass('years-menu__item--active').siblings().removeClass('years-menu__item--active');
		});
	}
	$('.content-slider').slick({
		prevArrow: '<button type="button" class="content-slider__arrow content-slider__arrow--prev"><svg><use xlink:href="/img/sprite.svg#icon-arrow-right"></use></svg></button>',
		nextArrow: '<button type="button" class="content-slider__arrow content-slider__arrow--next"><svg><use xlink:href="/img/sprite.svg#icon-arrow-left"></use></svg></button>',
		// adaptiveHeight: true
	});
	$('.press-slider').slick({
		slidesToShow: 2,
		prevArrow: '<button type="button" class="content-slider__arrow content-slider__arrow--prev"><svg><use xlink:href="/img/sprite.svg#icon-arrow-right"></use></svg></button>',
		nextArrow: '<button type="button" class="content-slider__arrow content-slider__arrow--next"><svg><use xlink:href="/img/sprite.svg#icon-arrow-left"></use></svg></button>',
		responsive: [
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});

	if($('.schedule-page').length) {
		var top = $('#schedule-sliding-section').offset().top
		$(window).scroll(function(e){
			if(wW >= 1200) {
				if($(window).scrollTop() - top >= 20) {
					$('#schedule-sliding-section').addClass('schedule-sliding-section--fixed');
					$('#schedule-sliding-spacer').show();
				} else {
					$('#schedule-sliding-section').removeClass('schedule-sliding-section--fixed');
					$('#schedule-sliding-spacer').hide();
				}
			}
		});
		$('#schedule_group_select').change(function (e) {
			var group = $(this).val();
			$('td[class^="group-col-"]').hide();
			$('td.group-col-' + group).show();
		})
	}

	/*$('.schedule td').click(function(){
		var currentLine = $(this).parents('tr');
		var stratDayCol;
		var finishDayCol;
		if(!currentLine.hasClass('schedule__start-of-day')) {
			stratDayCol = currentLine.prevAll('.schedule__start-of-day').get(0);
		} else {
			stratDayCol = currentLine;
		}
		if(!currentLine.hasClass('schedule__end-of-day')) {
			finishDayCol = currentLine.nextAll('.schedule__end-of-day').get(0);
		} else {
			finishDayCol = currentLine;
		}
		
		console.log(stratDayCol, finishDayCol);
	});*/

	$('.scrolled-table').each(function () {
		var container = $(this);
		var tbl = $(this).find('table');
		var containerWidth = container.width();
		var tblWith = tbl.width();

		if(!container.is(':visible')) {
			parents = $(this).parents().filter(function() { return $(this).css("display") == "none" });
			parents.show();
			containerWidth = container.width();
			tblWith = tbl.width();
			parents.hide();
		}

		if(tblWith > containerWidth) {
			container.append('<button class="scrolled-table__button scrolled-table__button--next"><svg><use xlink:href="/img/sprite.svg#icon-arrow-left"></use></svg></button><button class="scrolled-table__button scrolled-table__button--prev"><svg><use xlink:href="/img/sprite.svg#icon-arrow-right"></use></svg></button>');
			container.find('.scrolled-table__button svg').scrollToFixed({
				marginTop: 200,
				limit: container.offset().top + container.height() - 50
			});
			$(this).on('click', '.scrolled-table__button--next', function (e) {
				e.preventDefault();
				if (parseInt(tbl.css('margin-left')) - 100 > containerWidth - tblWith) {
					tbl.css({
						marginLeft: '-=100'
					});
				} else {
					tbl.css({
						marginLeft: containerWidth - tblWith
					});
					$(this).hide();
				}
				if(containerWidth - tblWith < 0) {
					container.find('.scrolled-table__button--prev').show();
				}
			});
			$(this).on('click', '.scrolled-table__button--prev', function (e) {
				e.preventDefault();
				if (parseInt(tbl.css('margin-left')) + 100 < 0) {
					tbl.css({
						marginLeft: '+=100'
					});
				} else {
					tbl.css({
						marginLeft: '0'
					});
					$(this).hide();
				}
				if(tblWith > containerWidth) {
					container.find('.scrolled-table__button--next').show();
				}
			});
		}
	});

	$('.show-you-variant').change(function (e) {
		if ($(this).prop('checked')) {
			$(this).parents('.custom-check').next('.you-variant-fl').show().attr('disabled', false);
		} else {
			$(this).parents('.custom-check').next('.you-variant-fl').hide().attr('disabled', true);
		}
	});

	$('.chosen-multi').chosen();
});

if($('.fluid-right-col').length) {
	var container = $('.fluid-right-col');
	var containerWidth = container.width();
	var tables = container.find('table');
	var maxWidth = 0;
	tables.each(function () {
		if($(this).width() > maxWidth) {
			maxWidth = $(this).width();
		}
	});
	if(maxWidth > containerWidth) {
		$('body').css('width', maxWidth+446+15).addClass('has-long-tbl');
	}
	/* for (const key in tables) {
		if (tables.hasOwnProperty(key)) {
			console.log(tables[key]);
			if($(tables[key]).width() > cotainerWidth) {
				$('body').css('width', $(tables[key]).width()+405+15).addClass('has-long-tbl');
				break;
			}
		}
	} */
}

// ymaps.ready(initMap);
// function initMap()
// {
// 	var map = new ymaps.Map("locations-widget__map1", {
// 		center: [55.76, 37.64],
// 		zoom: 10
// 	});
// }

$('#locations-widget-samgtu').locationWidget({
	activeCaptionClass: "locations-widget__item--active",
	dataPath: "/other/contacts/samgtu_places.json"
});
$('#locations-widget-academy').locationWidget({
	activeCaptionClass: "locations-widget__item--active",
	dataPath: "/other/contacts/academy_places.json"
});
$('#locations-widget-dormitories').locationWidget({
	activeCaptionClass: "locations-widget__item--active",
	dataPath: "/other/contacts/dormitories_places.json"
});
$('#locations-widget-branches').locationWidget({
	activeCaptionClass: "locations-widget__item--active",
	dataPath: "/other/contacts/branches_places.json"
});
$('#locations-widget-representation').locationWidget({
	activeCaptionClass: "locations-widget__item--active",
	dataPath: "/other/contacts/representation_places.json"
});